import React, {useEffect, useRef, useState} from "react";
import './CFAdminManageMappingRule.scss';
import CFAdminDataTable from "../../components/CFAdminDataTable";
import CFAdminModal from "../../components/CFAdminModal";
import AddIcon from "../../assets/images/add-icon.svg";
import {DeleteButton, EditButton} from "../../components/CFAdminDataTable/ActionsButtonComponents";
import VinImportService from "../../services/VinImportService";
import CFAdminConfirmDialog from "../../components/CFAdminConfirmDialog";
import CFAdminNotification from "../../components/CFAdminNotification";


function CFAdminManangeMappingRule() {

    const childCompRef = useRef<any>();
    const [ruleGroups, setRuleGroups] = useState([]);
    const [ruleGroupQueries, setRuleGroupQueries] = useState([]);
    const [ruleDescSelected, setRuleDescSelected] = useState("");
    const [fordFlag, setFordFlag] = useState(false);
    const [ruleSelectedCount, setRuleSelectedCount] = useState(0);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [ruleId, setRuleId] = useState(false);

    const GenerateConfigButton = ({ handleGenerate, props, titleDesc } : any) => (
        <div>
            <img
                className="hover-cursor"
                title={titleDesc}
                src={AddIcon}
                data-testid={"rule-query" + props.row.original.fieldK}
                onClick={() => handleRowClick(props.row.original.vinX, props.row.original.sptNonFordF)}
                alt="Xcelvations Logo"
                height="15"
            />
        </div>
    );

    const columnsRuleGroup = [
        {
            Header: "Rule Groups",
            accessor: 'sptNonFordF',
            Cell: ({ row }: any) => (
                <div className="cell-max-row-height">
                    {row.original.vinX} {row.original.sptNonFordF ? '[True]' : '[False]'}
                </div>
            )
        },
        {
            Header: "Actions",
            Cell: (props: any) => {
                return (
                    <GenerateConfigButton handleGenerate={handleGenerate} props={props} titleDesc="View Rule Queries" />
                );
            }
        }
    ];

    const ruleQueryColumns = [
        {
            Header: "Attribute Rules",
            accessor: 'ruleQuery',
            Cell: ({value}: any) => <div style={{ width: '1200px' }}>{value}</div>
        },
        {
            Header: "Actions",
            Cell: (props: any) => {

                return  (
                    <div className="cell-buttons">
                        {renderEditButton(props, handleChange)}
                        {renderDeleteButton(props, handleDelete)}
                    </div>
                )}
        },
    ];


    const renderEditButton = (props: any, handleChange: Function) => {
        return <EditButton {...props} handleChange={handleChange}/>
    }

    const renderDeleteButton = (props: any, handleDelete: Function, disabled: boolean = false) => {
        return <DeleteButton {...props} handleDelete={() => handleDelete(props.row.original.ruleId)} disabled={disabled} />;
    };

    const handleDelete = (ruleId: any) => {
        setRuleId(ruleId);
        setIsDelete(true);
    };

    const handleChange = (event: any) => {
        console.log("Edit Config for: ", event)
    }


    const handleGenerate = (props: any) => {
        console.log("Generate Config for: ", props.row.original)
    }

    const handleRowClick = (ruleDesc: string, fordFlag : boolean) => {
        setIsPopupOpen(true);
        ruleQueriesById(ruleDesc, fordFlag);
        setRuleDescSelected(ruleDesc);
        setFordFlag(fordFlag);
    };

    const ruleQueriesById = async (ruleDesc: any, fordFlag : boolean) => {
        console.log("Getting response");
        VinImportService.getRuleQueries(ruleDesc, fordFlag)
            .then(response => {
                setRuleGroupQueries(response.data);
                setRuleSelectedCount(response.data.length);
                console.log("Rule Queries response: ", response.data);
            })
            .catch(e => {
                confirmErrorMessage("Failed to get Latest Rule Queries")
                console.log(e);
            });
    };

    const confirmSuccessMessage = async (value: any) => {
        childCompRef.current.successMessage(value);
    }
    const confirmErrorMessage = async (value: any) => {
        childCompRef.current.failureMessage(value);
    };

    const ruleGroupIndex = async () => {
        VinImportService.getRuleGroupIndex()
            .then(response => {
                setRuleGroups(response.data);
            })
            .catch(e => {
                confirmErrorMessage("Failed to get Latest Rule Group Index")
                console.log(e);
            });
    };


    useEffect(() => {
        ruleGroupIndex();
    }, []);

    const invokeRuleDelete = (ruleId : any) => {

        VinImportService.deleteRuleById(ruleId)
        .then(() => {
            confirmSuccessMessage(`Deleted rule successfully.`);
            setIsDelete(false);
            ruleQueriesById(ruleDescSelected, fordFlag);
        })
        .catch(e => {
            confirmErrorMessage("Error while deleting Model!");
        });
    }

    return (
        <>
            <div className="vinimport-component">
                <h2 className="fomo-type--title">Manage Mapping Rules</h2>
                <div>
                    <div>
                        <div>
                            <div>
                                <div className="ext5">
                                    <div data-testid="ManageRuleDesc"> You may view a group of rules, create a new rule
                                        or apply all rules to the current data. <br/>
                                        The mapping rules are grouped by their mapped attributes. Click on a rule group
                                        link to view the rules for that attributes.<br/>
                                        <b>Note:</b> Rules with multiple mapped attributes will appear in each of the
                                        attribute groups.
                                    </div>

                                    <CFAdminDataTable
                                        columns={columnsRuleGroup}
                                        data={ruleGroups}
                                        textModal={''}
                                        showAdd={false}
                                        pageSize={1000}
                                    />

                                    <CFAdminModal
                                        isOpen={isPopupOpen}
                                        toggle={() => setIsPopupOpen(!isPopupOpen)}
                                        title="Rule Details"
                                    >
                                        <div data-testid='displayRuleQuery'>Rule for {ruleDescSelected}.<br/>
                                            Listed below are <b>[{ruleSelectedCount}] </b>rules that are mapped
                                            to {ruleDescSelected} attributes.<br/>
                                            You may edit or delete a rule, create a new rule or apply all rules to the
                                            current data.
                                        </div>
                                        <CFAdminDataTable
                                            columns={ruleQueryColumns}
                                            data={ruleGroupQueries}
                                            textModal={''}
                                            showAdd={false}
                                            pageSize={1000}
                                        />

                                        <CFAdminConfirmDialog
                                            data-testid="delete-rule"
                                            isOpen={isDelete}
                                            onClose={()=>setIsDelete(false)}
                                            onConfirm={()=>invokeRuleDelete(ruleId)}
                                            title={`Are you sure you want to delete rule?`}
                                            text="Are you sure you want to delete rule?"
                                        />
                                    </CFAdminModal>
                                </div>
                            </div>
                        </div>
                        <CFAdminNotification ref={childCompRef}/>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CFAdminManangeMappingRule;