import React from 'react';
import AddIcon from "../../assets/images/add-icon.svg"
import EditIcon from "../../assets/images/icons8-edit.svg"
import DeleteIcon from "../../assets/images/delete.svg"
import DeleteIconDisabled from "../../assets/images/delete-disabled.svg"

export const AddConfigButton = ({ cell: { row: { original: originalRow }, index: rowIndex }, handleAdd}: any) => {
    return (
        <div>
            <button data-testid="cell-add-config-button" className="edit-button" onClick={() => handleAdd({ originalRow, rowIndex })} title="Add Config">
                <img src={AddIcon} alt="Xcelvations Logo" height="15" />
            </button>
        </div>
    )
}

export const EditButton =  ({ cell: { row: { original: originalRow }, index: rowIndex }, handleChange}: any) => {
    return (
        <div>
            <button data-testid="cell-edit-button" className="edit-button" onClick={() => handleChange({ originalRow, rowIndex })} title="Edit">
                <img src={EditIcon} alt="Xcelvations Logo" height="15" />
            </button>
        </div>
    )
}

export const DeleteButton = ({ cell: { row: { original: originalRow }, index: rowIndex }, handleDelete, disabled}: any) => {
    return (
        <div>
            <button data-testid="cell-delete-button" className="edit-button" onClick={() => handleDelete({originalRow, rowIndex})} disabled={disabled}  title={disabled ? "In Use" : "Delete"}>
                <img src={disabled ? DeleteIconDisabled : DeleteIcon} alt="Xcelvations Logo" height="15"/>
            </button>
        </div>
    )
}
export const CheckboxButton = ({ cell: { column: { id: columnId }, row: { original: originalRow }, index: rowIndex }, className, handleChange, checked}: any) => {
    return (
        <div>
            <input data-testid="cell-check-button" className={className} type="checkbox" checked={checked} onChange={(event)=> handleChange({originalRow, rowIndex, columnId, checked: event.target.checked})} />
        </div>
    );
}

/* eslint-disable jsx-a11y/anchor-is-valid */
export const CellAnchorButton =  ({ cell: { row: { original: originalRow }, index: rowIndex }, handleChange}: any) => {
    return (
        <div>
            <button data-testid="cell-edit-button" className="edit-button" onClick={() => handleChange({ originalRow, rowIndex })} title="Edit">
                <a href="#"> {originalRow.id} </a>
            </button>
        </div>
    )
}

/* eslint-disable jsx-a11y/anchor-is-valid */
export const CellValueAnchorButton =  ({ value, bundleId, cell: { row: { original: originalRow }, index: rowIndex }, handleChange}: any) => {
    return (
        <div>
            <button data-testid="cell-value-edit-button" className="edit-button" onClick={() => handleChange({ originalRow, rowIndex, bundleId })} title="Edit">
                <a href="#"> {value} </a>
            </button>
        </div>
    )
}

/* eslint-disable jsx-a11y/anchor-is-valid */
export const CellValueOperationAnchorButton = ({ value, bundleId, handleChange }: any) => {
    return <a onClick={() => handleChange(bundleId)}> {value}</a>;
};

/* eslint-disable jsx-a11y/anchor-is-valid */
export const HeaderCellComponent =  ({ attributeDescription }: any) => {
    return (
        <div className="flex">
            <div className="inline-flex">{attributeDescription}</div>
        </div>
    )
}