import { slide as Menu } from "react-burger-menu";
import "./CFAdminNavigation.scss";

import {RiAdminFill, RiDatabase2Fill, RiUserSettingsFill} from "react-icons/ri";
import {GrConfigure, GrHostMaintenance, GrSystem} from "react-icons/gr";
import {MdOutlineBatchPrediction, MdWifiProtectedSetup} from "react-icons/md";
import {FcNext} from "react-icons/fc";

import {PiMathOperationsThin} from "react-icons/pi";
import Accordion from "./Accordion";
import {Link} from "react-router-dom";
import {AccordionData, AppProps} from "./types";
import React from "react";

const operationsMenuItems = [
  { id: 1, label: 'Operations', link: '/operations', img : <FcNext />},
];
const sysMainMenuItems = [
  { id: 1, label: 'Countries', link: '/countries', img : <FcNext />},
  { id: 2, label: 'Language', link: '/language', img : <FcNext />},
  { id: 3, label: 'Country Languages', link: '/countryLanguages', img : <FcNext />},
  { id: 4, label: 'Makes', link: '/makes', img : <FcNext />},
  { id: 5, label: 'Models', link: '/models', img : <FcNext />}
];
const mainSetupMenuItems = [
    { id: 1, label: 'Bundles', link: '/bundles', img : <FcNext />},
    { id: 2, label: 'Intervals', link: '/intervals', img : <FcNext />},
    { id: 3, label: 'Driving Conditions', link: '/drivingconds', img : <FcNext />},
];
const vehConfigMenuItems = [
    { id: 1, label: 'Create New Vehicles', link: '/createnewveh', img : <FcNext />},
    { id: 2, label: 'Create and Delete Configurations', link: '/createconfig', img : <FcNext />},
    { id: 3, label: 'System Suggested Configurations', link: '/systemsuggconfig', img : <FcNext />},
    { id: 4, label: 'System Suggested Vehicles', link: '/systemsuggvehicle', img : <FcNext />},
    { id: 5, label: 'UnSupported Vehicles', link: '/unsupportvehicle', img : <FcNext />},
    { id: 6, label: 'Maintenance Schedule Home', link: '/maintenanceschedule', img : <FcNext />},
];
const maintenanceRecommendMenuItems = [
    { id: 1, label: 'Bulk Bundle Assignment', link: '/bulkBundleAssignment', img : <FcNext />},
    { id: 2, label: 'Bulk Operation Assignment', link: '/bulkOperationAssignment', img : <FcNext />},
    { id: 3, label: 'Clone Vehicle Profile and Maintenance', link: '/cloneVehicleProfile', img : <FcNext />},
    { id: 4, label: 'Copy Maintenance', link: '/copyMaintenance', img : <FcNext />},
    { id: 5, label: 'Vehicle Bundle Assignment', link: '/vehicleBundleAssignment', img : <FcNext />},
    { id: 6, label: 'Vehicle Operation Assignment', link: '/vehicleOperationAssignment', img : <FcNext />},
    { id: 7, label: 'Validate IOLM Intervals', link: '/iolmIntervals', img : <FcNext />},
];

const publishMenuItems = [
    { id: 1, label: 'Data Publish', link: '/publish', img : <FcNext />},
];

const vinAdminMenuItems = [
    { id: 1, label: 'Vin Import', link: '/vinimport', img : <FcNext />},
    { id: 2, label: 'Manage Mapping Rules', link: '/managemappingrules', img : <FcNext />},
];

const testClientMenuItems = [
    { id: 1, label: 'Decode Vin', link: '/decodeVin', img : <FcNext />},
];

const testClientsItem: AccordionData = {id: 2, title: 'Test Clients',content: (
        <div>
            {testClientMenuItems.map((item) => (
                <Link key={item.id} className="accordion-subitem-btn" to={item.link}> {item.img} {item.label}</Link>
            ))}
        </div>
    ), img: <RiUserSettingsFill/>
}


const operationItem: AccordionData = {
    id: 4, title: 'Operations', content: (
        <div>
            {operationsMenuItems.map((item) => (
                <Link key={item.id} className="accordion-subitem-btn" to={item.link}> {item.img} {item.label}</Link>
   ))}
   </div>
),img : <PiMathOperationsThin />}

const sysMainMenuItem: AccordionData = {id: 1, title: 'System Maintenance',content: (
  <div>
    {sysMainMenuItems.map((item) => (
        <Link key={item.id} className="accordion-subitem-btn" to={item.link}> {item.img} {item.label}</Link>
   ))}
  </div>
),img : <GrSystem />}


const maintenanceItem: AccordionData = {id: 5, title: 'Maintenance Setup',content:(
        <div>
            {mainSetupMenuItems.map((item) => (
                <Link key={item.id} className="accordion-subitem-btn" to={item.link}> {item.img} {item.label}</Link>
            ))}
        </div>
    ),img : <MdWifiProtectedSetup />}

const vehConfigItem: AccordionData = {id: 6, title: 'Vehicle Configurations',content:(
        <div>
            {vehConfigMenuItems.map((item) => (
                <Link key={item.id} className="accordion-subitem-btn" to={item.link}> {item.img} {item.label}</Link>
            ))}
        </div>
    ),img : <GrConfigure />}

const maintenanceRecommendItem: AccordionData = {id: 7, title: 'Maintenance Recommendations',content:(
        <div>
            {maintenanceRecommendMenuItems.map((item) => (
                <Link key={item.id} className="accordion-subitem-btn" to={item.link}> {item.img} {item.label}</Link>
            ))}
        </div>
    ),img : <GrHostMaintenance />}

const dataPublishItem: AccordionData = {id: 8, title: 'Data Publishing',content:(
        <div>
            {publishMenuItems.map((item) => (
                <Link key={item.id} className="accordion-subitem-btn" to={item.link}> {item.img} {item.label}</Link>
            ))}
        </div>
    ),img : <RiDatabase2Fill />}

const vinAdminItem: AccordionData = {id: 9, title: 'VIN Admin',content:(
        <div>
            {vinAdminMenuItems.map((item) => (
                <Link key={item.id} className="accordion-subitem-btn" to={item.link}> {item.img} {item.label}</Link>
            ))}
        </div>
    ),img : <RiAdminFill />}


function getInitials(name: string): string {
    const [firstName, lastName] = name.split(' ');
  
    if (firstName && lastName) {
      return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
    }
  
    return firstName.charAt(0).toUpperCase();
}

function CFAdminNavigation (user : Readonly<AppProps>) {

    const [isMenuOpen, setIsMenuOpen] = React.useState(false);
    const handleCloseMenu = () => {
        setIsMenuOpen(false);
    };
    const handleStateChange = (state: { isOpen: boolean | ((prevState: boolean) => boolean); }) => {
        setIsMenuOpen(state.isOpen);
    };

    let authAccordionItems : any[] = [];

    if(user.canSystemMaintenance) authAccordionItems.push(sysMainMenuItem);
    if(user.canGSMOperations) authAccordionItems.push(operationItem);
    if(user.canDataAdmin) authAccordionItems.push(maintenanceItem);
    if(user.canDataAdmin) authAccordionItems.push(vehConfigItem);
    if(user.canDataAdmin) authAccordionItems.push(maintenanceRecommendItem);
    if(user.canDataPublish) authAccordionItems.push(dataPublishItem);
    if(user.canTestClients) authAccordionItems.push(testClientsItem);
    if(user.canVinImport) authAccordionItems.push(vinAdminItem);

    return (
    <Menu isOpen={isMenuOpen} onStateChange={handleStateChange} >
      <div className='initials-avatar'>
        <div>{getInitials(user.ssoUserDetails.name)}</div>
      </div>
      <div className='memu-title avatar-name'>
         <h3 className='text'>{user.ssoUserDetails.name}</h3>
      </div>
      <div>
        <Accordion menuClose={handleCloseMenu} items={authAccordionItems}/>
      </div>
      <div className="logout">

      </div>
    </Menu>
  );
}

export default CFAdminNavigation;
