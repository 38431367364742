import React, { useState } from "react";
import VehicleConfigService from "../../services/VehicleConfigService";
import CFAdminModal from "../../components/CFAdminModal";
import CFAdminDataTable from "../../components/CFAdminDataTable";
import './CFAdminDecodeVin.scss';

interface VinData {
    vin: string;
    errorMessage: string;
    make: string;
    model: string;
    year: string;
    restraintsDriver: string;
    cab: string;
    transmission: string;
    vehicleType: string;
    engineDisplacement: string;
    brakesWeightRatingGVWR: string;
    series: string;
    assemblyCountry: string;
    axleWheelbase: string;
    engineManufacturer: string;
    assemblyPlant: string;
    bodyStyle: string;
    fuel: string;
    brakesPower: string;
    driveTrain: string;
    cylinders: string;
}

function CFAdminDecodeVin() {
    const [vins, setVins] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [data, setData] = useState<VinData[]>([]);
    const [showTable, setShowTable] = useState(false);
    const [selectedRow, setSelectedRow] = useState<VinData | null>(null);

    const toggleModal = () => {
        setIsOpen(!isOpen);
    }

    const handleTextareaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setVins(event.target.value);
    };

    const resetForm = () => {
        setVins('');
        setShowTable(false);
    };

    const decodeVin = async () => {
        let vin = vins.split("\n");
        VehicleConfigService.decodeTestVin({ vin })
            .then(response => {
                const extractedData = response.data.decodeAttributes.map((item: { vin: any; errorMessage: any; attributeMap: { [key: string]: { attributeValue: any; }; }; }) => ({
                    vin: item.vin,
                    errorMessage: item.errorMessage,
                    make: item.attributeMap?.Make?.attributeValue,
                    model: item.attributeMap?.Model?.attributeValue,
                    year: item.attributeMap?.Year?.attributeValue,
                    restraintsDriver: item.attributeMap?.RestraintsDriver?.attributeValue,
                    cab: item.attributeMap?.Cab?.attributeValue,
                    transmission: item.attributeMap?.Transmission?.attributeValue,
                    vehicleType: item.attributeMap?.VehicleType?.attributeValue,
                    engineDisplacement: item.attributeMap?.EngineDisplacement?.attributeValue,
                    brakesWeightRatingGVWR: item.attributeMap?.BrakesWeightRatingGVWR?.attributeValue,
                    series: item.attributeMap?.Series?.attributeValue,
                    assemblyCountry: item.attributeMap?.AssemblyCountry?.attributeValue,
                    axleWheelbase: item.attributeMap?.AxleWheelbase?.attributeValue,
                    engineManufacturer: item.attributeMap?.EngineManufacturer?.attributeValue,
                    assemblyPlant: item.attributeMap?.AssemblyPlant?.attributeValue,
                    bodyStyle: item.attributeMap?.BodyStyle?.attributeValue,
                    fuel: item.attributeMap?.Fuel?.attributeValue,
                    brakesPower: item.attributeMap?.BrakesPower?.attributeValue,
                    driveTrain: item.attributeMap?.DriveTrain?.attributeValue,
                    cylinders: item.attributeMap?.Cylinders?.attributeValue,
                }));
                setData(extractedData);
                setShowTable(true);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const openModal = (row: VinData) => {
        setSelectedRow(row);
        setIsOpen(true);
    };

    const closeModal = () => {
        setSelectedRow(null);
        setIsOpen(false);
    };

    const columns = [
        {
            Header: "VIN",
            accessor: 'vin'
        },
        {
            Header: "Status",
            accessor: 'errorMessage',
            Cell: ({ value }: any) => (
                <span style={{ color: value === 'None' ? 'green' : 'red' }}>
                    {value === 'None' ? 'Success' : value}
                </span>
            )
        },
        {
            Header: "Make",
            accessor: 'make'
        },
        {
            Header: "Model",
            accessor: 'model'
        },
        {
            Header: "Year",
            accessor: 'year'
        },
        {
            Header: "Actions",
            Cell: ({ row }: any) => (
                <button onClick={() => openModal(row.original)}>
                    Show Details
                </button>
            )
        }
    ];

    const downloadCSV = () => {
        const csvRows = [
            ["VIN", "Status", "Make", "Model", "Year"],
            ...data.map(row => [row.vin, row.errorMessage, row.make, row.model, row.year])
        ];

        const csvContent = csvRows.map(e => e.join(",")).join("\n");
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "vin_decode_results.csv");
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <>
            <div className="vinimport-component">
                <h2 className="fomo-type--title">Decode VIN</h2>
                <div className="ext3">
                    Please enter the VINs that need to be validated, then click on 'Decode VIN.'
                </div>
                {!showTable && (
                    <textarea
                        rows={50}
                        style={{width: "25%", height: "500px", overflowY: "scroll"}}
                        value={vins}
                        onChange={handleTextareaChange}
                    />
                )}
                {showTable && (
                    <div className="modal-content-vin">
                        <div style={{width: "75%", overflowX: "auto"}}>
                            <CFAdminDataTable
                                columns={columns}
                                data={data}
                                toggleModal={toggleModal}
                                textModal={''}
                                showAdd={false}
                            />
                        </div>
                    </div>
                )}
                <div className="modal-footer button-frame">
                    <button data-testid="step-cancel" className="fvp-button--outline fvp-button"
                            type="submit"
                            onClick={resetForm}>
                        <i className="fvp-left-chevron"/>Cancel
                    </button>
                    <div className="right-align">
                        {!showTable && (
                            <button
                                data-testid="step-release-vin"
                                className="fvp-button button-right-align"
                                type="button"
                                onClick={decodeVin}
                            >
                                Decode VIN
                            </button>
                        )}
                    </div>
                    {showTable && (
                        <button
                            className="fvp-button button-right-align"
                            type="button"
                            onClick={downloadCSV}
                        >
                            Download CSV
                        </button>
                    )}
                </div>
            </div>
            {selectedRow && (
                <CFAdminModal
                    isOpen={isOpen}
                    toggle={toggleModal}
                    isAdd={false}
                    title={'Vin Decode Results'}
                >
                    <div className="table-container">
                        <table className="table-details">
                            <tbody>
                            <tr>
                                <th>Make</th>
                                <td>{selectedRow.make}</td>
                            </tr>
                            <tr>
                                <th>Model</th>
                                <td>{selectedRow.model}</td>
                            </tr>
                            <tr>
                                <th>Year</th>
                                <td>{selectedRow.year}</td>
                            </tr>
                            <tr>
                                <th>Restraints Driver</th>
                                <td>{selectedRow.restraintsDriver}</td>
                            </tr>
                            <tr>
                                <th>Cab</th>
                                <td>{selectedRow.cab}</td>
                            </tr>
                            <tr>
                                <th>Transmission</th>
                                <td>{selectedRow.transmission}</td>
                            </tr>
                            <tr>
                                <th>Vehicle Type</th>
                                <td>{selectedRow.vehicleType}</td>
                            </tr>
                            <tr>
                                <th>Engine Displacement</th>
                                <td>{selectedRow.engineDisplacement}</td>
                            </tr>
                            <tr>
                                <th>Brakes Weight Rating GVWR</th>
                                <td>{selectedRow.brakesWeightRatingGVWR}</td>
                            </tr>
                            <tr>
                                <th>Series</th>
                                <td>{selectedRow.series}</td>
                            </tr>
                            <tr>
                                <th>Assembly Country</th>
                                <td>{selectedRow.assemblyCountry}</td>
                            </tr>
                            <tr>
                                <th>Axle Wheelbase</th>
                                <td>{selectedRow.axleWheelbase}</td>
                            </tr>
                            <tr>
                                <th>Engine Manufacturer</th>
                                <td>{selectedRow.engineManufacturer}</td>
                            </tr>
                            <tr>
                                <th>Assembly Plant</th>
                                <td>{selectedRow.assemblyPlant}</td>
                            </tr>
                            <tr>
                                <th>Body Style</th>
                                <td>{selectedRow.bodyStyle}</td>
                            </tr>
                            <tr>
                                <th>Fuel</th>
                                <td>{selectedRow.fuel}</td>
                            </tr>
                            <tr>
                                <th>Brakes Power</th>
                                <td>{selectedRow.brakesPower}</td>
                            </tr>
                            <tr>
                                <th>Drive Train</th>
                                <td>{selectedRow.driveTrain}</td>
                            </tr>
                            <tr>
                                <th>Cylinders</th>
                                <td>{selectedRow.cylinders}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <button onClick={closeModal}>Close</button>
                </CFAdminModal>
            )
            }
        </>
    )
        ;
}

export default CFAdminDecodeVin;