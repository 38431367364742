import {Formik} from 'formik';
import * as Yup from 'yup';
import React, {useState} from 'react';
import {FVPTextInput} from '../../components/formField';
import VinImportService from '../../services/VinImportService';
import CFAdminConfirmDialog from '../../components/CFAdminConfirmDialog';
import {VinPattern} from "./index";

const schema = Yup.object().shape({
    patternId: Yup.number(),
    vinPattern: Yup.string().required("VIN Pattern is a required field"),
    year: Yup.number().required("Year is a required field"),
    make: Yup.string().required("Make is a required field"),
    model: Yup.string().required("Model is a required field")
});

type VinPatternProps = {
    vinPattern? : VinPattern;
    toggleModal: any;
    refreshData: any;
    successMessage: any;
    errorMessage: any;
    isDelete: boolean;
};

function CFAdminVinPatternForm({
                                   vinPattern,
                                   toggleModal,
                                   refreshData,
                                   successMessage,
                                   errorMessage,
                                   isDelete
                               }: VinPatternProps) {
    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

    const saveVinPattern = (values: any) => {
        const data = {
            patternId: values.patternId,
            vinPattern: values.vinPattern,
            year: +values.year,
            make: values.make,
            model: values.model,
            series: values.series,
            bodyType: values.bodyType,
            engineDisplacement: values.engineDisplacement,
            cylinders: values.cylinders,
            fuel: values.fuel,
            driveTrain: values.driveTrain
        };

        VinImportService.createInternalVin(data)
            .then(() => {
                successMessage(values.vinPattern + " added successfully.");
                refreshData();
                toggleModal();
            })
            .catch((e: any) => {
                console.error(e);
                errorMessage("Error while creating VIN data.");
            });
    };

    const updateVinPattern = (values: any) => {
        const data = {
            patternId: values.patternId,
            vinPattern: values.vinPattern,
            year: values.year,
            make: values.make,
            model: values.model,
            series: values.series,
            bodyType: values.bodyType,
            engineDisplacement: values.engineDisplacement,
            cylinders: values.cylinders,
            fuel: values.fuel,
            driveTrain: values.driveTrain
        };

        VinImportService.updateInternalVin(data)
            .then(() => {
                successMessage(values.vinPattern + " updated successfully.");
                refreshData();
                toggleModal();
            })
            .catch((e: any) => {
                console.error(e);
                errorMessage("Error while updating VIN data");
            });
    };

    const deleteVinPattern = () => {
        if (vinPattern?.patternId !== undefined) {
            VinImportService.deleteInternalVin(vinPattern.patternId)
                .then(() => {
                    successMessage(`VIN Pattern: ${vinPattern.vinPattern} deleted successfully.`);
                    refreshData();
                    toggleModal();
                })
                .catch((e: any) => {
                    console.error(e);
                    errorMessage("Error while deleting VIN Pattern!");
                });
        } else {
            errorMessage("Error while deleting VIN Pattern!");
        }
    };

    return (
        <>
            <Formik
                validationSchema={schema}
                initialValues={{
                    patternId: vinPattern?.patternId ?? 0,
                    vinPattern: vinPattern?.vinPattern ?? '',
                    year: vinPattern?.year ?? '',
                    make: vinPattern?.make ?? '',
                    model: vinPattern?.model ?? '',
                    series: vinPattern?.series ?? '',
                    bodyType: vinPattern?.bodyType ?? '',
                    engineDisplacement: vinPattern?.engineDisplacement ?? '',
                    cylinders: vinPattern?.cylinders ?? '',
                    fuel: vinPattern?.fuel ?? '',
                    driveTrain: vinPattern?.driveTrain ?? ''
                }}
                onSubmit={(values) => {
                    if (isDelete) {
                        setIsDeleteConfirmOpen(true);
                    } else {
                        if (vinPattern?.patternId !== undefined && vinPattern?.patternId > 0) {
                            updateVinPattern(values);
                        } else {
                            saveVinPattern(values);
                        }
                    }
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isValid,
                      dirty
                  }) => (
                    <div className="fvp-form">
                        <div className="wide-data-table">
                            <form data-testid="SubmitForm" noValidate onSubmit={handleSubmit}>
                                <div className="modal-body">
                                    <div className="fvp-form__group">
                                        <div className="fvp-form__subgroup fvp-form__subgroup--two-columns">
                                            <FVPTextInput label="VIN Pattern:" name="vinPattern" required
                                                          readOnly={isDelete}/>
                                            <FVPTextInput label="Year:" name="year" required readOnly={isDelete}/>
                                            <FVPTextInput label="Make:" name="make" required readOnly={isDelete}/>
                                            <FVPTextInput label="Model:" name="model" required readOnly={isDelete}/>
                                            <FVPTextInput label="Series:" name="series"  readOnly={isDelete}/>
                                            <FVPTextInput label="Body Type:" name="bodyType"
                                                          readOnly={isDelete}/>
                                            <FVPTextInput label="Engine Displacement:" name="engineDisplacement"
                                                           readOnly={isDelete}/>
                                            <FVPTextInput label="Cylinders:" name="cylinders"
                                                          readOnly={isDelete}/>
                                            <FVPTextInput label="Fuel:" name="fuel"
                                                          readOnly={isDelete}/>
                                            <FVPTextInput label="Drive Train:" name="driveTrain"
                                                          readOnly={isDelete}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer button-frame">
                                    <button data-testid="Cancel" className="fvp-button--outline" onClick={toggleModal}>
                                        <i className="fvp-left-chevron"/>
                                        Cancel
                                    </button>
                                    {isDelete ? (
                                        <button data-testid="Delete" className="fvp-button-danger--outline"
                                                type="submit">
                                            Delete
                                            <i className="fvp-right-chevron"/>
                                        </button>
                                    ) : (
                                        <button data-testid="Save" className="fvp-button" type="submit"
                                                disabled={!(dirty && isValid)}>
                                            {values.patternId === undefined || values.patternId === 0 ? 'Save' : 'Update'}
                                            <i className="fvp-right-chevron"/>
                                        </button>
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                )}
            </Formik>
            <CFAdminConfirmDialog
                isOpen={isDeleteConfirmOpen}
                onClose={() => setIsDeleteConfirmOpen(false)}
                onConfirm={deleteVinPattern}
                title={"Delete VIN Pattern: " + vinPattern?.vinPattern}
                text="Are you sure you want to delete this VIN Pattern?"
            />
        </>
    );
}

export default CFAdminVinPatternForm;